import { render, staticRenderFns } from "./discover.vue?vue&type=template&id=65fa16d2&scoped=true"
import script from "./discover.vue?vue&type=script&lang=js"
export * from "./discover.vue?vue&type=script&lang=js"
import style0 from "./discover.vue?vue&type=style&index=0&id=65fa16d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65fa16d2",
  null
  
)

export default component.exports